import AtlasElement from "./AtlasElement";
import { Point2d } from "../../../common/types/Geometry";

export default abstract class AtlasDimensionalElement extends AtlasElement {
    protected _width: number;
    protected _height: number;

    constructor(args?: {
        x?: number | (() => number);
        y?: number | (() => number);
        width?: number;
        height?: number;
    }) {
        super({ x: args?.x ?? 0, y: args?.y ?? 0 });

        this._width = args?.width ?? 1;
        this._height = args?.height ?? 1;
    }

    public get width(): number {
        return this._width;
    }

    public get height(): number {
        return this._height;
    }

    public get left(): number {
        return this.x;
    }

    public get top(): number {
        return this.y;
    }

    public get right(): number {
        return this.x + this._width;
    }

    public get bottom(): number {
        return this.y + this._height;
    }

    public get center(): Point2d {
        return { x: this.x + this._width / 2, y: this.y + this._height / 2 };
    }

    public get topLeft(): Point2d {
        return { x: this.x, y: this.y };
    }

    public get topRight(): Point2d {
        return { x: this.x + this._width, y: this.y };
    }

    public get bottomLeft(): Point2d {
        return { x: this.x, y: this.y + this._height };
    }

    public get bottomRight(): Point2d {
        return { x: this.x + this._width, y: this.y + this._height };
    }

    public set width(val: number) {
        this._width = val;
    }

    public set height(val: number) {
        this._height = val;
    }

    /** Returns true if otherElement's bounds and this element's bounds intersect at any point */
    public overlaps(otherElement: AtlasElement) {
        if (otherElement instanceof AtlasDimensionalElement) {
            if (
                (this.x >= otherElement.x && this.x <= otherElement.right) ||
                (this.right >= otherElement.x &&
                    this.right <= otherElement.right) ||
                (otherElement.x >= this.x && otherElement.x <= this.right) ||
                (otherElement.right >= this.x &&
                    otherElement.right <= this.right)
            ) {
                if (
                    (this.y >= otherElement.y &&
                        this.y <= otherElement.bottom) ||
                    (this.bottom >= otherElement.y &&
                        this.bottom <= otherElement.bottom) ||
                    (otherElement.y >= this.y &&
                        otherElement.y <= this.bottom) ||
                    (otherElement.bottom >= this.y &&
                        otherElement.bottom <= this.bottom)
                ) {
                    return true;
                }
            }
        } else {
            if (
                otherElement.x >= this.x &&
                otherElement.x <= this.right &&
                otherElement.x >= this.y &&
                otherElement.y <= this.bottom
            ) {
                return true;
            }
        }

        return false;
    }
}
