import { Dictionary } from "../../../utils/sys/Dictionary";
import atlasPinKeyMap from "../../../assets/json/atlas/atlas_pin_map.json";
import ImageSlicer from "../../../common/utilities/ImageSlicer";
import { KeyValuePair } from "../../../utils/sys/KeyValuePair";
import { Rect2d } from "../../../common/types/Geometry";

import atlasMarkers from "../../../assets/images/atlas/png/atlas_markers.png";

export default class AtlasPinImage {
    static atlasImageSlicer = new ImageSlicer(atlasMarkers, 20, 20);
    static keyIndexMap: Dictionary<string, number> = new Dictionary(
        atlasPinKeyMap.map(
            (entry) =>
                ({ key: entry.key, value: entry.index } as KeyValuePair<
                    string,
                    number
                >)
        )
    );

    static get image() {
        return this.atlasImageSlicer.image;
    }

    static getImageCoordinatesFromKey(key: string): Rect2d | null {
        if (this.keyIndexMap.hasKey(key)) {
            return this.atlasImageSlicer.getPixelCoordsFromIndex(
                this.keyIndexMap.get(key) ?? 0
            );
        }

        return null;
    }
}
