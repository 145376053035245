import AttributePanel from "../AttributePanel/AttributePanel";
import { CharacterData } from "../../../../utils/game/character/CharacterData";
import StatBoard from "../../../../utils/game/mechanics/StatBoard";
import FlexColumn from "../../../../common/components/layout/Flexbox/FlexColumn";
import FlexRow from "../../../../common/components/layout/Flexbox/FlexRow";

type Props = {
    alignment?: "start" | "center" | "end";
    characterData: CharacterData;
    statBoard: StatBoard;
};

export default function AttributesContainer({
    alignment,
    characterData,
    statBoard,
}: Props): JSX.Element {
    return (
        <FlexColumn width={"full"} style={{ marginBottom: "4px" }}>
            <FlexRow alignment="center">
                <div
                    style={{
                        fontSize: "80%",
                        fontWeight: "bold",
                        padding: 4,
                    }}
                >
                    Attributes
                </div>
            </FlexRow>
            <FlexRow alignment={alignment}>
                <AttributePanel statBoard={statBoard} statKey="AWA" />
                <AttributePanel statBoard={statBoard} statKey="WGTALL" />
                <AttributePanel statBoard={statBoard} statKey="REG" />
            </FlexRow>
            <FlexRow alignment={alignment}>
                <AttributePanel statBoard={statBoard} statKey="SUR" />
                <AttributePanel statBoard={statBoard} statKey="WIL" />
                <AttributePanel statBoard={statBoard} statKey="STLTH" />
            </FlexRow>
        </FlexColumn>
    );
}
