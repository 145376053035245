import { useContext, useMemo } from "react";
import PageFramework from "../../common/components/layout/PageFramework/PageFramework";
import { useSearchParams } from "react-router-dom";
import StatBoard from "../../utils/game/mechanics/StatBoard";
import CharacterSheetContainer from "./components/CharacterSheet/CharacterSheetContainer";
import NoCharacterContainer from "./NoCharacterContainer";

export default function CharacterPage(): JSX.Element {
    const [searchParams, _] = useSearchParams();

    const characterID = useMemo((): number | null => {
        const strID = searchParams.get("id");
        if (strID != null) {
            const numID = Number.parseInt(strID);
            if (!Number.isNaN(numID)) {
                return numID;
            }
        }

        return null;
    }, [searchParams]);

    return (
        <PageFramework noPadding={characterID != null}>
            {characterID == null && <NoCharacterContainer />}
            {characterID != null && (
                <CharacterSheetContainer characterID={characterID} />
            )}
        </PageFramework>
    );
}
