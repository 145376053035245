import { Rect2d } from "../../../../common/types/Geometry";
import useCanvas from "../../../../common/hooks/RenderingHooks";
import { WorldGrid } from "./WorldGrid";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { Point2d } from "../../../../common/types/Geometry";

import "./WorldGridCanvas_style.css";

type Props = {
    width: number;
    height: number;
    className?: string;
    style?: React.CSSProperties;
    worldGrid: WorldGrid;
};

export default function WorldGridCanvas(props: Props): JSX.Element {
    const canvasRef = useCanvas((context: CanvasRenderingContext2D) => {
        const cW = context.canvas.width;
        const cH = context.canvas.height;

        context.fillStyle = "white";
        context.fillRect(0, 0, cW, cH);

        const offset = { x: 25, y: -25 };
        const radius = 20;
        const apothem = (Math.sqrt(3) / 2) * radius;

        const gridSize = props.worldGrid.dimensions;

        for (let gridX = 0; gridX < gridSize.x; ++gridX) {
            for (let gridY = 0; gridY < gridSize.y; ++gridY) {
                const cell = props.worldGrid.getCell(gridX, gridY);
                const origin: Point2d = {
                    x: gridX * radius * 1.5,
                    y:
                        gridX % 2 === 0
                            ? cH - gridY * (apothem * 2)
                            : cH - (gridY * (apothem * 2) + apothem),
                };

                context.beginPath();
                for (let rad = 0; rad < Math.PI * 2; rad += (Math.PI * 2) / 6) {
                    const x = Math.cos(rad);
                    const y = Math.sin(rad);

                    context.lineTo(
                        offset.x + origin.x + x * radius,
                        offset.y + origin.y + y * radius
                    );
                }

                if (cell != null) {
                    if (cell.hasProperty("color")) {
                        context.fillStyle = cell.getProperty("color") as string;
                        context.fill();
                    }

                    /* if(cell.)
                    context.fillStyle =
                        Math.floor(Math.random() * 3) % 2 === 0
                            ? "red"
                            : "green"; */
                }

                context.stroke();
            }
        }
    });

    return (
        <canvas
            width={props.width}
            height={props.height}
            ref={canvasRef}
            className={`grid-canvas${
                props.className == null ? "" : props.className
            }`}
            style={props.style}
        />
    );
}
