import AtlasPinImage from "./AtlasPinImage";
import AtlasViewport from "./AtlasViewport";
import AtlasDimensionalElement from "./AtlasDimensionalElement";
import { Rect2d } from "../../../common/types/Geometry";

export default class AtlasPinElement extends AtlasDimensionalElement {
    protected _pinX: number | (() => number);
    protected _pinY: number | (() => number);
    protected _pinZ: number | (() => number);
    protected _mark: string | null;
    protected _icon: string | null;
    protected _title: string | null;

    constructor(args?: {
        pinX?: number | (() => number);
        pinY?: number | (() => number);
        pinZ?: number;
        mark?: string;
        icon?: string;
        title?: string;
    }) {
        const width: number = 32;
        const height = 32;

        const getX = () => {
            if (typeof this._pinX === "number") {
                return this._pinX - width / 2;
            } else {
                return this._pinX() - width / 2;
            }
        };

        const getY = () => {
            if (typeof this._pinY === "number") {
                return this._pinY - height / 2;
            } else {
                return this._pinY() - height / 2;
            }
        };

        super({
            x: getX,
            y: getY,
            width,
            height,
        });

        this._pinX = args?.pinX ?? 0;
        this._pinY = args?.pinY ?? 0;
        this._pinZ = args?.pinZ ?? 0;

        this._mark = args?.mark ?? null;
        this._icon = args?.icon ?? null;

        this._title = args?.title ?? null;
    }

    public get pinX(): number {
        if (typeof this._pinX === "number") {
            return this._pinX;
        } else {
            return this._pinX();
        }
    }

    public get pinY(): number {
        if (typeof this._pinY === "number") {
            return this._pinY;
        } else {
            return this._pinY();
        }
    }

    public override draw(
        ctx: CanvasRenderingContext2D,
        viewport: AtlasViewport
    ) {
        if (AtlasPinImage.image != null) {
            if (this._icon != null) {
                const iconCoords = AtlasPinImage.getImageCoordinatesFromKey(
                    this._icon
                );
                if (iconCoords != null) {
                    ctx.drawImage(
                        AtlasPinImage.image,
                        iconCoords.x,
                        iconCoords.y,
                        iconCoords.w,
                        iconCoords.h,
                        (this.pinX - viewport.x) * viewport.magnificationX -
                            iconCoords.w / 2,
                        (this.pinY - viewport.y) * viewport.magnificationY -
                            iconCoords.h / 2,
                        this.width,
                        this.height
                    );
                }
            }

            if (this._mark != null) {
                const markCoords = AtlasPinImage.getImageCoordinatesFromKey(
                    this._mark
                );
                if (markCoords != null) {
                    ctx.drawImage(
                        AtlasPinImage.image,
                        markCoords.x,
                        markCoords.y,
                        markCoords.w,
                        markCoords.h,
                        (this.pinX - viewport.x) * viewport.magnificationX -
                            markCoords.w / 2,
                        (this.pinY - viewport.y) * viewport.magnificationY -
                            markCoords.h / 2,
                        this.width,
                        this.height
                    );
                }
            }
        }

        if (this._title != null) {
            ctx.fillStyle = "white";
            ctx.font = "24px serif";
            ctx.fillText(
                this._title,
                (this.pinX - viewport.x) * viewport.magnificationX + 20,
                (this.pinY - viewport.y) * viewport.magnificationY + 8
            );
        }
    }
}
