import ActiveUserContext from "../../utils/session/ActiveUserContext";
import FlexRow from "../../common/components/layout/Flexbox/FlexRow";
import PageFramework from "../../common/components/layout/PageFramework/PageFramework";
import { useCallback, useContext, useEffect, useState } from "react";
import WorldGridCanvas from "./components/world_grid/WorldGridCanvas";
import { WorldGrid } from "./components/world_grid/WorldGrid";
import { Point2d } from "../../common/types/Geometry";
import { LivingWorldGrid } from "./components/world_grid/LivingWorldGrid";

export default function GridPage(): JSX.Element {
    const activeUser = useContext(ActiveUserContext);
    const worldGrid: LivingWorldGrid = new LivingWorldGrid({
        width: 20,
        height: 20,
    });

    // Control World Grid update loop
    useEffect(() => {
        const interval = setInterval(() => {
            worldGrid.gridUpdate();
        }, 100);

        return () => clearInterval(interval);
    }, []);

    return (
        <PageFramework>
            <FlexRow>
                <WorldGridCanvas
                    width={800}
                    height={800}
                    style={{ width: "800px", height: "800px" }}
                    worldGrid={worldGrid}
                />
            </FlexRow>
        </PageFramework>
    );
}
