import React, { ReactNode } from "react";
import "./BorderedPanel_style.css";

export default function BorderedPanel(props: {
    borderType?: 1 | 2;
    children?: React.ReactNode;
    style?: React.CSSProperties | undefined;
}): JSX.Element {
    const borderType = props.borderType ?? 1;
    const style = props.style;

    return (
        <div className={`bordered_panel_wrapper_s${borderType}`} style={style}>
            <div className="bordered_panel_border" />
            <div className="bordered_panel_contents">{props.children}</div>
        </div>
    );
}
