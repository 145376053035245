import { CharacterData } from "../../../../utils/game/character/CharacterData";
import { Blue, Red, Yellow } from "../../../../utils/sys/Color";
import { CharacterDataParser } from "../../../../utils/game/character/CharacterDataParser";
import { DynamicInventoryDisplayMode } from "../InventoryItemCollection/InventoryItemDynamicCollection";
import { InventoryItemContainerProps } from "../InventoryItemSlot/InventoryItemContainer";
import InventoryItemTransferContext, {
    DefaultInventoryItemTransferContextValue,
} from "../../../../utils/sys/InventoryItemTransferContext";
import { KeyValuePair } from "../../../../utils/sys/KeyValuePair";
import { UIInventoryItemContainerClickParams } from "../InventoryItemSlot/InventoryItemContainer";
import { useCallback, useState } from "react";

import AttributePanel from "../AttributePanel/AttributePanel";
import AttributesContainer from "../AttributesContainer/AttributesContainer";
import BorderedPanel from "../../../../common/components/display/BorderedPanel/BorderedPanel";
import CharacterSheetAbilitiesTab from "./CharacterSheetAbilitiesTab/CharacterSheetAbilitiesTab";
import CharacterSheetInfoTab from "./CharacterSheetInfoTab/CharacterSheetInfoTab";
import CharacterSheetInventoryTab from "./CharacterSheetInventoryTab/CharacterSheetInventoryTab";
import CharacterSheetNotesTab from "./CharacterSheetNotesTab/CharacterSheetNotesTab";
import CharacterSheetTalentsTab from "./CharacterSheetTalentsTab/CharacterSheetTalentsTab";
import CharacterSheetTraitsTab from "./CharacterSheetTraitsTab/CharacterSheetTraitsTab";
import CharacterHeader from "../CharacterHeader/CharacterHeader";
import FlexColumn from "../../../../common/components/layout/Flexbox/FlexColumn";
import FlexRow from "../../../../common/components/layout/Flexbox/FlexRow";
import Meter from "../../../../common/components/input/Meter/Meter";
import MouseTrackingComponent from "../../../../common/components/wrappers/MouseTrackingComponent/MouseTrackingComponent";
import NumberInputToggleField from "../../../../common/components/input/NumberInputToggleField/NumberInputToggleField";
import ProficienciesContainer from "../ProficienciesContainer/ProficienciesContainer";
import ResistancesContainer from "../ResistancesContainer/ResistancesContainer";
import StatBoard from "../../../../utils/game/mechanics/StatBoard";
import TabControl from "../../../../common/components/layout/TabControl/TabControl";
import TextField from "../../../../common/components/display/TextField/TextField";

import "./CharacterSheet_style.css";
import InventoryItemIconSlot from "../InventoryItemSlot/InventoryItemIconSlot";
import SwitchCircle from "../../../../assets/images/common/svg/switch_circle.svg";

const statBoard = new StatBoard();

type Props = {
    characterData: CharacterData;
    isEditable?: boolean;
    onCharacterDataValueChanged?: (vals: KeyValuePair[]) => void;
};

export default function CharacterSheet({
    characterData,
    isEditable = false,
    onCharacterDataValueChanged,
}: Props): JSX.Element {
    const [dmgDisplayMode, setDmgDisplayMode] = useState<
        "physical" | "magical"
    >("physical");
    const [selectedFeaturesTab, setSelectedFeaturesTab] = useState<
        string | null
    >(null);
    const [selectedInfoTab, setSelectedInfoTab] = useState<string | null>(null);
    const [inventoryDisplayMode, setInventoryDisplayMode] =
        useState<DynamicInventoryDisplayMode>("list");
    const [selectedAbilitiesTab, setSelectedAbilitiesTab] = useState<
        string | null
    >(null);
    const [selectedNotesTab, setSelectedNotesTab] = useState<string | null>(
        null
    );
    const [selectedItemContainer, setSelectedItemContainer] =
        useState<InventoryItemContainerProps | null>(null);
    const [visibleDamageType, setVisibleDamageType] = useState<
        "physical" | "magical"
    >("physical");
    const [talentPointSpendEnabled, setTalentPointSpendEnabled] =
        useState<boolean>(false);
    const [pendingTalentPointSpend, setPendingTalentPointSpend] = useState<
        Array<{ id: number; points: number }>
    >([]);
    const [schoolPointSpendEnabled, setSchoolPointSpendEnabled] =
        useState<boolean>(false);
    const [pendingSchoolPointSpend, setPendingSchoolPointSpend] = useState<
        Array<{ id: number; points: number }>
    >([]);
    const [equipmentExpanded, setEquipmentExpanded] = useState<boolean>(true);
    const [inventoryExpanded, setInventoryExpanded] = useState<boolean>(true);
    const [walletExpanded, setWalletExpanded] = useState<boolean>(true);

    const inventoryItemTransferContext =
        DefaultInventoryItemTransferContextValue;

    const updateSelectedItemContainer = useCallback(
        (selection: InventoryItemContainerProps | null) => {
            setSelectedItemContainer(selection);
            inventoryItemTransferContext.storedItem = selection?.item ?? null;
        },
        [selectedItemContainer, inventoryItemTransferContext]
    );

    const [isEditingTalents, setEditingTalents] = useState<boolean>(false);
    const [isEditingSchools, setEditingSchools] = useState<boolean>(false);

    CharacterDataParser.parseCharacterDataToStatBoard(characterData, statBoard);

    const itemClick = useCallback(
        (params: UIInventoryItemContainerClickParams | undefined) => {
            if (selectedItemContainer === null) {
                updateSelectedItemContainer(params?.containerProps ?? null);
            } else {
                if (
                    selectedItemContainer?.item === params?.containerProps?.item
                ) {
                    updateSelectedItemContainer(null);
                } else {
                    const item1 = selectedItemContainer?.item ?? null;
                    const item2 = params?.containerProps?.item ?? null;

                    const restrictor1 =
                        selectedItemContainer?.itemRestrictor ?? null;
                    const restrictor2 =
                        params?.containerProps?.itemRestrictor ?? null;

                    const callback1 =
                        selectedItemContainer?.setItemCallback ?? null;
                    const callback2 =
                        params?.containerProps?.setItemCallback ?? null;

                    const validTransfer =
                        (item1 === null ||
                            restrictor2 === null ||
                            restrictor2(item1)) &&
                        (item2 === null ||
                            restrictor1 === null ||
                            restrictor1(item2));

                    if (validTransfer) {
                        const updates: Array<KeyValuePair> = [];
                        const response1 = callback1?.(item2);
                        const response2 = callback2?.(item1);

                        if (response1 != null && response1.slotKey != null) {
                            if (response1.type === "collection") {
                                updates.push({
                                    key: response1.slotKey,
                                    value: JSON.stringify(
                                        response1.items.map((item) =>
                                            item.toJSON()
                                        )
                                    ),
                                });
                            } else if (response1.type === "container") {
                                updates.push({
                                    key: response1.slotKey,
                                    value:
                                        response1.item === null
                                            ? null
                                            : response1.item.toJSON(),
                                });
                            }
                        }

                        if (response2 != null && response2.slotKey != null) {
                            if (response2.type === "collection") {
                                updates.push({
                                    key: response2.slotKey,
                                    value: JSON.stringify(
                                        response2.items.map((item) =>
                                            item.toJSON()
                                        )
                                    ),
                                });
                            } else if (response2.type === "container") {
                                updates.push({
                                    key: response2.slotKey,
                                    value:
                                        response2.item === null
                                            ? null
                                            : JSON.stringify(
                                                  response2.item.toJSON()
                                              ),
                                });
                            }
                        }
                        if (updates.length > 0) {
                            onCharacterDataValueChanged?.(updates);
                            selectedItemContainer?.afterItemChange?.();
                            params?.containerProps?.afterItemChange?.();
                        }
                    } else {
                        console.log("Invalid Transfer");
                    }

                    updateSelectedItemContainer(null);
                }
            }
        },
        [selectedItemContainer]
    );

    return (
        <InventoryItemTransferContext.Provider
            value={inventoryItemTransferContext}
        >
            <FlexColumn className="character-sheet">
                <div className="character-sheet-row-span-2 no-padding">
                    <CharacterHeader characterData={characterData} />
                </div>
                <FlexRow>
                    <div className="character-sheet-row-span-1">
                        <ProficienciesContainer
                            characterData={characterData}
                            statBoard={statBoard}
                            isEditable={isEditable}
                            onCharacterDataValueChanged={
                                onCharacterDataValueChanged
                            }
                        />
                    </div>
                    <div className="character-sheet-row-span-1">
                        <Meter
                            fillColor={Red}
                            currentValue={characterData.currentHP}
                            maxValue={characterData.maxHP}
                            meterClassName="hp-meter"
                            isEditable={isEditable}
                            isCurrentEditable={true}
                            isMaxEditable={true}
                            onValuesChanged={(vals) => {
                                if (
                                    vals.current !== characterData.currentHP ||
                                    vals.max !== characterData.maxHP
                                ) {
                                    onCharacterDataValueChanged?.([
                                        {
                                            key: "hp_current",
                                            value: vals.current,
                                        },
                                        { key: "hp_max", value: vals.max },
                                    ]);
                                }
                            }}
                        />
                        <FlexRow gap={8}>
                            <Meter
                                fillColor={Yellow}
                                currentValue={Number.parseInt(
                                    statBoard.getValueByStatKey("EP")
                                )}
                                maxValue={Number.parseInt(
                                    statBoard.getValueByStatKey("EPMAX")
                                )}
                                meterClassName="ep-meter"
                            />
                            <NumberInputToggleField
                                className="ep-meter-adjust"
                                label="Adj"
                                value={characterData.epAdjust}
                                onStopEditing={(val) => {
                                    onCharacterDataValueChanged?.([
                                        { key: "stamina_adj", value: val },
                                    ]);
                                }}
                            ></NumberInputToggleField>
                            <div className="exhaustion-label">
                                <TextField type="bold">Exhaustion</TextField>
                            </div>
                        </FlexRow>
                        <FlexRow gap={8}>
                            <Meter
                                fillColor={Blue}
                                currentValue={Number.parseInt(
                                    statBoard.getValueByStatKey("MP")
                                )}
                                maxValue={Number.parseInt(
                                    statBoard.getValueByStatKey("MPMAX")
                                )}
                                meterClassName="mp-meter"
                            />
                            <NumberInputToggleField
                                className="mp-meter-adjust"
                                label="Adj"
                                value={characterData.mpAdjust}
                                onStopEditing={(val) => {
                                    onCharacterDataValueChanged?.([
                                        { key: "channel_adj", value: val },
                                    ]);
                                }}
                            ></NumberInputToggleField>
                            <div className="exhaustion-col">
                                <NumberInputToggleField
                                    value={characterData.exhaustion}
                                    onStopEditing={(val) => {
                                        onCharacterDataValueChanged?.([
                                            { key: "exhaust", value: val },
                                        ]);
                                    }}
                                    displayFieldStyle={{ textAlign: "center" }}
                                ></NumberInputToggleField>
                            </div>
                        </FlexRow>
                    </div>
                </FlexRow>
                <FlexRow>
                    <div className="character-sheet-row-span-1">
                        <FlexRow alignment="center">
                            <BorderedPanel borderType={2}>
                                <FlexColumn
                                    gap={8}
                                    style={{
                                        borderRadius: 10,
                                        borderColor: "black",
                                        borderStyle: "solid",
                                        borderWidth: 1,
                                        padding: 4,
                                        height: "100%",
                                    }}
                                >
                                    <AttributePanel
                                        statBoard={statBoard}
                                        statKey="DEF"
                                    />
                                    <AttributePanel
                                        statBoard={statBoard}
                                        statKey="MDEF"
                                    />
                                    <AttributePanel
                                        statBoard={statBoard}
                                        statKey="DDG"
                                    />
                                    <AttributePanel
                                        statBoard={statBoard}
                                        statKey="SPD"
                                    />
                                </FlexColumn>
                            </BorderedPanel>
                            <BorderedPanel borderType={2}>
                                <FlexColumn
                                    style={{
                                        borderRadius: 10,
                                        borderColor: "black",
                                        borderStyle: "solid",
                                        borderWidth: 1,
                                        padding: "20px 4px 4px 4px",
                                        height: "100%",
                                    }}
                                >
                                    <FlexRow alignment="center" width="full">
                                        <FlexRow
                                            gap={4}
                                            style={{
                                                marginBottom: 8,
                                                padding: 4,
                                                borderRadius: 4,
                                                borderColor: "black",
                                                borderStyle: "solid",
                                                borderWidth: 1,
                                            }}
                                            width="auto"
                                        >
                                            {characterData.lh == null &&
                                                characterData.rh == null && (
                                                    <InventoryItemIconSlot
                                                        id="lh"
                                                        isEditable={false}
                                                        item={null}
                                                        emptyIconSrc="gloves_blank"
                                                        tooltipEnabled={false}
                                                    />
                                                )}
                                            {characterData.lh != null && (
                                                <InventoryItemIconSlot
                                                    id="lh"
                                                    isEditable={false}
                                                    item={characterData.lh}
                                                />
                                            )}
                                            {characterData.rh != null && (
                                                <InventoryItemIconSlot
                                                    id="rh"
                                                    isEditable={false}
                                                    item={characterData.rh}
                                                />
                                            )}
                                        </FlexRow>
                                    </FlexRow>
                                    {dmgDisplayMode === "physical" && (
                                        <>
                                            <FlexRow>
                                                <AttributePanel
                                                    statBoard={statBoard}
                                                    statKey="ACC"
                                                />
                                                <AttributePanel
                                                    statBoard={statBoard}
                                                    statKey="RNG"
                                                />
                                            </FlexRow>

                                            <FlexRow>
                                                <AttributePanel
                                                    statBoard={statBoard}
                                                    statKey="POW"
                                                />
                                                <AttributePanel
                                                    statBoard={statBoard}
                                                    statKey="DMG"
                                                />
                                            </FlexRow>
                                        </>
                                    )}
                                    {dmgDisplayMode === "magical" && (
                                        <>
                                            <FlexRow>
                                                <AttributePanel
                                                    statBoard={statBoard}
                                                    statKey="MACC"
                                                />
                                                <AttributePanel
                                                    statBoard={statBoard}
                                                    statKey="MRNG"
                                                />
                                            </FlexRow>

                                            <FlexRow>
                                                <AttributePanel
                                                    statBoard={statBoard}
                                                    statKey="MPOW"
                                                />
                                                <AttributePanel
                                                    statBoard={statBoard}
                                                    statKey="MDMG"
                                                />
                                            </FlexRow>
                                        </>
                                    )}
                                    <FlexRow
                                        alignment="end"
                                        style={{ padding: 4 }}
                                        width="full"
                                    >
                                        <button
                                            className="dmg-switch-button"
                                            onClick={(e) => {
                                                setDmgDisplayMode(
                                                    dmgDisplayMode ===
                                                        "physical"
                                                        ? "magical"
                                                        : "physical"
                                                );
                                            }}
                                        >
                                            <img
                                                src={SwitchCircle}
                                                alt="Switch"
                                            />
                                        </button>
                                    </FlexRow>
                                </FlexColumn>
                            </BorderedPanel>
                        </FlexRow>
                        <FlexRow alignment="center">
                            <BorderedPanel
                                borderType={2}
                                style={{ width: "100%" }}
                            >
                                <AttributesContainer
                                    alignment="center"
                                    characterData={characterData}
                                    statBoard={statBoard}
                                />
                            </BorderedPanel>
                        </FlexRow>
                        <FlexRow alignment="center">
                            <BorderedPanel
                                borderType={2}
                                style={{ width: "100%" }}
                            >
                                <ResistancesContainer
                                    alignment="center"
                                    characterData={characterData}
                                    statBoard={statBoard}
                                />
                            </BorderedPanel>
                        </FlexRow>
                    </div>
                    <div className="character-sheet-row-span-1">
                        <div className="features-container">
                            <TabControl
                                id="character_sheet_feature_tab_control"
                                selected={selectedFeaturesTab}
                                onSelectedTabChange={setSelectedFeaturesTab}
                                tabs={[
                                    { id: "info", label: "Info" },
                                    { id: "traits", label: "Traits" },
                                    { id: "talents", label: "Talents" },
                                    { id: "inventory", label: "Inventory" },
                                    { id: "abilities", label: "Abilities" },
                                    { id: "notes", label: "Notes" },
                                ]}
                            />
                            <div className="features-content-panel">
                                {selectedFeaturesTab === "info" && (
                                    <CharacterSheetInfoTab
                                        characterData={characterData}
                                        selectedTab={selectedInfoTab}
                                        onSelectedTabChanged={
                                            setSelectedInfoTab
                                        }
                                        isEditable={isEditable}
                                        onCharacterDataValueChanged={
                                            onCharacterDataValueChanged
                                        }
                                    />
                                )}
                                {selectedFeaturesTab === "traits" && (
                                    <CharacterSheetTraitsTab
                                        characterData={characterData}
                                        statBoard={statBoard}
                                        isEditable={isEditable}
                                        onCharacterDataValueChanged={
                                            onCharacterDataValueChanged
                                        }
                                    />
                                )}
                                {selectedFeaturesTab === "talents" && (
                                    <CharacterSheetTalentsTab
                                        characterData={characterData}
                                        statBoard={statBoard}
                                        isEditable={isEditable}
                                        isEditing={isEditingTalents}
                                        onEditingChanged={(editing) => {
                                            setEditingTalents(editing);
                                        }}
                                        pointSpendEnabled={
                                            talentPointSpendEnabled
                                        }
                                        onPointSpendEnabledChanged={
                                            setTalentPointSpendEnabled
                                        }
                                        pendingTalentPointSpend={
                                            pendingTalentPointSpend
                                        }
                                        onPendingTalentPointSpendChanged={
                                            setPendingTalentPointSpend
                                        }
                                        onCharacterDataValueChanged={
                                            onCharacterDataValueChanged
                                        }
                                    />
                                )}
                                {selectedFeaturesTab === "abilities" && (
                                    <CharacterSheetAbilitiesTab
                                        characterData={characterData}
                                        selectedTab={selectedAbilitiesTab}
                                        onSelectedTabChanged={
                                            setSelectedAbilitiesTab
                                        }
                                        isEditable={isEditable}
                                        isEditing={isEditingSchools}
                                        onEditingChanged={setEditingSchools}
                                        pointSpendEnabled={
                                            schoolPointSpendEnabled
                                        }
                                        onPointSpendEnabledChanged={
                                            setSchoolPointSpendEnabled
                                        }
                                        pendingSchoolPointSpend={
                                            pendingSchoolPointSpend
                                        }
                                        onPendingSchoolPointSpendChanged={
                                            setPendingSchoolPointSpend
                                        }
                                        onCharacterDataValueChanged={
                                            onCharacterDataValueChanged
                                        }
                                    />
                                )}
                                {selectedFeaturesTab === "inventory" && (
                                    <CharacterSheetInventoryTab
                                        characterData={characterData}
                                        isEditable={isEditable}
                                        inventoryDisplayMode={
                                            inventoryDisplayMode
                                        }
                                        onInventoryDisplayModeChanged={
                                            setInventoryDisplayMode
                                        }
                                        selectedItem={
                                            selectedItemContainer?.item ?? null
                                        }
                                        onItemClick={itemClick}
                                        onCharacterDataValueChanged={
                                            onCharacterDataValueChanged
                                        }
                                        equipmentExpanded={equipmentExpanded}
                                        onEquipmentExpandedChanged={
                                            setEquipmentExpanded
                                        }
                                        inventoryExpanded={inventoryExpanded}
                                        onInventoryExpandedChanged={
                                            setInventoryExpanded
                                        }
                                        walletExpanded={walletExpanded}
                                        onWalletExpandedChanged={
                                            setWalletExpanded
                                        }
                                    />
                                )}
                                {selectedFeaturesTab === "notes" && (
                                    <CharacterSheetNotesTab
                                        characterData={characterData}
                                        selectedTab={selectedNotesTab}
                                        onSelectedTabChanged={
                                            setSelectedNotesTab
                                        }
                                        isEditable={isEditable}
                                        onCharacterDataValueChanged={
                                            onCharacterDataValueChanged
                                        }
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                </FlexRow>
                <MouseTrackingComponent
                    enabled={false}
                    offset={{ x: 10, y: 10 }}
                />
            </FlexColumn>
        </InventoryItemTransferContext.Provider>
    );
}
