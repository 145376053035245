import AtlasViewport from "./AtlasViewport";
import { Point2d } from "../../../common/types/Geometry";

export default abstract class AtlasElement {
    protected _x: number | (() => number);
    protected _y: number | (() => number);

    constructor(args?: {
        x?: number | (() => number);
        y?: number | (() => number);
        sortIndex?: number;
    }) {
        this._x = args?.x ?? 0;
        this._y = args?.y ?? 0;
    }

    public get x(): number {
        if (typeof this._x === "number") {
            return this._x;
        } else {
            return this._x();
        }
    }
    public set x(val: number | (() => number)) {
        this._x = val;
    }

    public get y(): number {
        if (typeof this._y === "number") {
            return this._y;
        } else {
            return this._y();
        }
    }
    public set y(val: number | (() => number)) {
        this._y = val;
    }

    public get position(): Point2d {
        return { x: this.x, y: this.y };
    }

    public set position(val: Point2d) {
        this.x = val.x;
        this.y = val.y;
    }

    public translate(x: number, y: number): void {
        if (typeof this._x !== "number" || typeof this._y !== "number") {
            console.error(
                "Attempting to translate an Atlas Element with functionally-determined coordinates"
            );
        } else {
            this._x += x;
            this._y += y;
        }
    }

    /** Render the atlas element. Gets called every animation frame, so long as the element is not being culled. */
    public abstract draw(
        ctx: CanvasRenderingContext2D,
        viewport: AtlasViewport
    ): void;
}
