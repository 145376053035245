import { Point2d } from "../../../common/types/Geometry";
import { MutableRefObject, useEffect } from "react";

export type CanvasMouseEvent = {
    position: Point2d;
};

export function useAtlasCanvasInputHandler(
    ref: React.RefObject<HTMLCanvasElement>,
    callbacks?: {
        onMouseMove?: (event: MouseEvent) => void;
        onMouseDrag?: (event: MouseEvent) => void;
        onMouseDown?: (event: MouseEvent) => void;
        onMouseUp?: (event: MouseEvent) => void;
        onMouseWheel?: (event: WheelEvent) => void;
    }
) {
    function handleMouseMove(event: MouseEvent) {
        event.preventDefault();
        callbacks?.onMouseMove?.(event);
    }

    function handleMouseDrag(event: MouseEvent) {
        event.preventDefault();
        callbacks?.onMouseDrag?.(event);
    }

    function handleMouseDown(event: MouseEvent) {
        event.preventDefault();

        // Remove existing listener, if it exists
        document.removeEventListener("mousemove", handleMouseDrag);

        document.addEventListener("mousemove", handleMouseDrag);

        callbacks?.onMouseDown?.(event);
    }

    function handleMouseUp(event: MouseEvent) {
        event.preventDefault();

        document.removeEventListener("mousemove", handleMouseDrag);

        callbacks?.onMouseUp?.(event);
    }

    function handleMouseWheel(event: WheelEvent) {
        event.preventDefault();

        callbacks?.onMouseWheel?.(event);
    }

    useEffect(() => {
        ref.current?.addEventListener("mousemove", handleMouseMove);
        ref.current?.addEventListener("mousedown", handleMouseDown);
        document.addEventListener("mouseup", handleMouseUp);
        ref.current?.addEventListener("wheel", handleMouseWheel);

        return () => {
            ref.current?.removeEventListener("mousemove", handleMouseMove);
            ref.current?.removeEventListener("mousedown", handleMouseDown);
            document.removeEventListener("mouseup", handleMouseUp);
            ref.current?.removeEventListener("wheel", handleMouseWheel);
        };
    }, [
        ref,
        ref?.current,
        callbacks,
        callbacks?.onMouseDown,
        callbacks?.onMouseDrag,
        callbacks?.onMouseMove,
        callbacks?.onMouseUp,
        callbacks?.onMouseWheel,
    ]);
}
