import CreateTrackableAsyncFunction from "../utils/CreateTrackableAsyncFunction";
import { AtlasMarkerJSON } from "../utils/sys/AtlasMarkerJSON";

const getMarkersURL = "https://ayaseye.com/api/atlas/get_atlas_markers.php";

async function getAtlasMarkers(args: {
    atlas_id: number;
    left_frustum?: number;
    right_frustum?: number;
    top_frustum?: number;
    bottom_frustum?: number;
    near_frustum?: number;
    far_frustum?: number;
    token?: string;
}): Promise<Array<AtlasMarkerJSON>> {
    const payload = {
        ...args,
    };

    const response = await fetch(getMarkersURL, {
        method: "POST",
        body: JSON.stringify(payload),
    }).then<Array<AtlasMarkerJSON>>(async (res) => {
        const resultData = await res.json();

        if (Array.isArray(resultData)) {
            return resultData;
        } else {
            return [];
        }
    });

    return response;
}

export function useGetAtlasMarkers() {
    return CreateTrackableAsyncFunction(getAtlasMarkers);
}
