import { WorldGrid } from "./WorldGrid";

class LivingWorldGrid extends WorldGrid {
    private _isUpdating: boolean = false;

    public advanceState() {
        this.forEachCell((cell) => cell.advanceState());
    }

    public gridUpdate() {
        if (!this._isUpdating) {
            this._isUpdating = true;

            this.forEachCell((cell) => {
                const neighbors = cell.neighborCells;
                const cellColor = cell.getProperty("color");

                const redCount = neighbors.filter(
                    (n) => n.getProperty("color") === "red"
                ).length;
                const orangeCount = neighbors.filter(
                    (n) => n.getProperty("color") === "orange"
                ).length;
                const yellowCount = neighbors.filter(
                    (n) => n.getProperty("color") === "yellow"
                ).length;

                if (redCount > 3) {
                    cell.pendProperty("color", "red");
                } else if (redCount > 0) {
                    if (cell.getPendingProperty("color") !== "red") {
                        cell.pendProperty("color", "orange");
                    }
                }

                if (orangeCount > 3) {
                    if (cell.getPendingProperty("color") !== "red") {
                        cell.pendProperty("color", "orange");
                    }
                } else if (orangeCount > 0) {
                    if (
                        cell.getPendingProperty("color") !== "red" &&
                        cell.getPendingProperty("color") !== "orange"
                    ) {
                        cell.pendProperty("color", "yellow");
                    }
                }

                if (yellowCount > 4) {
                    if (
                        cell.getPendingProperty("color") !== "red" &&
                        cell.getPendingProperty("color") !== "orange"
                    ) {
                        cell.pendProperty("color", "yellow");
                    }
                }

                switch (cellColor) {
                    case "red":
                        if (cell.getPendingProperty("color") !== "red") {
                            cell.pendProperty("color", "orange");
                        }
                        break;
                    case "orange":
                        if (
                            cell.getPendingProperty("color") !== "red" &&
                            cell.getPendingProperty("color") !== "orange"
                        ) {
                            cell.pendProperty("color", "yellow");
                        }
                        break;
                    case "yellow":
                        if (
                            cell.getPendingProperty("color") !== "red" &&
                            cell.getPendingProperty("color") !== "orange" &&
                            cell.getPendingProperty("color") !== "yellow"
                        ) {
                            cell.pendProperty("color", null);
                        }
                        break;
                }
            });

            for (let i = 0; i < 10; ++i) {
                if (Math.random() > 0.75) {
                    const r = {
                        x: Math.floor(Math.random() * this.width),
                        y: Math.floor(Math.random() * this.height),
                    };

                    this.getCell(r.x, r.y)?.pendProperty("color", "red");
                }
            }

            this.advanceState();

            this._isUpdating = false;
        }
    }

    get isUpdating() {
        return this._isUpdating;
    }
}

export { LivingWorldGrid };
