import AtlasElement from "./AtlasElement";
import AtlasMapElement from "./AtlasMapElement";

export default class AtlasUniverse {
    private _elements: Array<AtlasElement>;

    constructor(args?: { elements?: Array<AtlasElement> }) {
        this._elements = new Array<AtlasElement>();

        if (args?.elements != null) {
            args.elements.forEach((element) => this.addElement(element));
        }
    }

    public get elements() {
        return this._elements;
    }

    public addElement(element: AtlasElement) {
        if (!this._elements.includes(element)) {
            this._elements.push(element);
        }
    }

    public removeElement(element: AtlasElement) {
        const index = this._elements.findIndex((e) => e === element);

        if (index >= 0) {
            this._elements.splice(index, 1);
        }
    }

    public sortElements() {
        this._elements.sort((a, b) => {
            const scores: Array<number> = [a, b].map((element) => {
                let indexScore = 0;

                if (!(element instanceof AtlasMapElement)) {
                    indexScore += 1000;
                }

                return indexScore;
            });

            return scores[1] - scores[0];
        });
    }
}
