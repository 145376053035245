import AtlasDimensionalElement from "./AtlasDimensionalElement";
import { Point2d } from "../../../common/types/Geometry";

export default class AtlasViewport extends AtlasDimensionalElement {
    static MagnificationMax: number = 1000;
    static MagnificationMin: number = 0.1;

    protected _nativeWidth: number;
    protected _nativeHeight: number;

    constructor(args?: {
        x?: number;
        y?: number;
        width?: number;
        height?: number;
    }) {
        super({
            x: args?.x ?? 0,
            y: args?.y ?? 0,
            width: args?.width ?? 1,
            height: args?.height ?? 1,
        });

        this._nativeWidth = args?.width ?? 1;
        this._nativeHeight = args?.height ?? 1;
    }

    public get nativeWidth(): number {
        return this._nativeWidth;
    }
    public set nativeWidth(val: number) {
        this._nativeWidth = val;
    }

    public get nativeHeight(): number {
        return this._nativeHeight;
    }
    public set nativeHeight(val: number) {
        this._nativeHeight = val;
    }

    public get magnificationX(): number {
        return this.nativeWidth / this.width;
    }

    public get magnificationY(): number {
        return this.nativeHeight / this.height;
    }

    public viewportPointToUniversePoint(point: Point2d) {
        return {
            x: point.x / this.magnificationX + this.x,
            y: point.y / this.magnificationY + this.y,
        };
    }

    public universePointToViewportPoint(point: Point2d) {
        return {
            x: (point.x - this.x) * this.magnificationX,
            y: (point.y - this.y) * this.magnificationY,
        };
    }

    public magnify(factor: number, canvasAnchor?: Point2d) {
        const newMagnificationX = Math.max(
            Math.min(
                this.magnificationX * factor,
                AtlasViewport.MagnificationMax
            ),
            AtlasViewport.MagnificationMin
        );
        const newMagnificationY = Math.max(
            Math.min(
                this.magnificationY * factor,
                AtlasViewport.MagnificationMax
            ),
            AtlasViewport.MagnificationMin
        );

        if (canvasAnchor != null) {
            const worldAnchor = this.viewportPointToUniversePoint(canvasAnchor);
            const newPos = {
                x:
                    worldAnchor.x -
                    (worldAnchor.x - this.x) *
                        (newMagnificationX - this.magnificationX),
                y:
                    worldAnchor.y -
                    (worldAnchor.y - this.y) *
                        (newMagnificationY - this.magnificationY),
            };

            console.log(this.x, canvasAnchor.x, worldAnchor.x);

            /* this._x = newPos.x;
            this._y = newPos.y; */
        }

        this._width = this._nativeWidth / newMagnificationX;
        this._height = this._nativeHeight / newMagnificationY;
    }

    public override draw(
        ctx: CanvasRenderingContext2D,
        viewport: AtlasViewport
    ) {
        ctx.lineWidth = 4;
        ctx.strokeStyle = "white";

        ctx.beginPath();
        ctx.moveTo(this.nativeWidth / 2 - 20, this.nativeHeight / 2);
        ctx.lineTo(this.nativeWidth / 2 + 20, this.nativeHeight / 2);
        ctx.moveTo(this.nativeWidth / 2, this.nativeHeight / 2 - 20);
        ctx.lineTo(this.nativeWidth / 2, this.nativeHeight / 2 + 20);
        ctx.stroke();

        ctx.lineWidth = 1;
        ctx.strokeStyle = "white";

        ctx.beginPath();
        ctx.moveTo(0, -viewport.y * viewport.magnificationY);
        ctx.lineTo(this.nativeWidth, -viewport.y * viewport.magnificationY);
        ctx.moveTo(-viewport.x * viewport.magnificationX, 0);
        ctx.lineTo(-viewport.x * viewport.magnificationX, this.nativeHeight);
        ctx.stroke();
    }
}
