import { CharacterData } from "../../../../utils/game/character/CharacterData";
import FlexColumn from "../../../../common/components/layout/Flexbox/FlexColumn";
import FlexRow from "../../../../common/components/layout/Flexbox/FlexRow";

import "./CharacterHeader_style.css";

type Props = {
    characterData: CharacterData;
};

export default function CharacterHeader({ characterData }: Props): JSX.Element {
    return (
        <FlexRow className="character-sheet-header">
            <h2>{characterData.name}</h2>
            <h3>{`${characterData.gender}${
                characterData.race
                    ? ` ${characterData.race.name}`
                    : ` ${characterData.raceText}`
            } - Level ${characterData.level}`}</h3>
        </FlexRow>
    );
}
