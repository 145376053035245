import { Dictionary } from "../../../../utils/sys/Dictionary";
import { WorldGridCell } from "./WorldGridCell";
import { Point2d } from "../../../../common/types/Geometry";

class WorldGrid {
    protected _cells: Dictionary<number, Dictionary<number, WorldGridCell>>;

    constructor(
        args: { width: number; height: number } = { width: 100, height: 100 }
    ) {
        this._cells = new Dictionary<
            number,
            Dictionary<number, WorldGridCell>
        >();
        for (let x = 0; x < args.width; ++x) {
            this._cells.set(x, new Dictionary<number, WorldGridCell>());
            for (let y = 0; y < args.height; ++y) {
                const cell = new WorldGridCell({ x, y });
                this._cells.get(x)?.set(y, cell);
            }
        }

        for (let x = 0; x < args.width; ++x) {
            for (let y = 0; y < args.height; ++y) {
                const targetCell = this._cells.get(x)?.get(y);

                if (targetCell != null) {
                    const n = this._cells.get(x)?.get(y + 1) ?? null;
                    const ne = this._cells.get(x + 1)?.get(y + (x % 2)) ?? null;
                    const se =
                        this._cells.get(x + 1)?.get(y + (x % 2) - 1) ?? null;
                    const s = this._cells.get(x)?.get(y - 1) ?? null;
                    const nw = this._cells.get(x - 1)?.get(y + (x % 2)) ?? null;
                    const sw =
                        this._cells.get(x - 1)?.get(y + (x % 2) - 1) ?? null;

                    targetCell.northCell = n;
                    targetCell.northeastCell = ne;
                    targetCell.southeastCell = se;
                    targetCell.southCell = s;
                    targetCell.southwestCell = sw;
                    targetCell.northwestCell = nw;
                }
            }
        }
    }

    get width(): number {
        return this._cells.length;
    }
    get height(): number {
        return this._cells.get(0)?.length ?? 0;
    }
    get dimensions(): Point2d {
        return { x: this.width, y: this.height };
    }

    public getCell(x: number, y: number): WorldGridCell | null {
        return this._cells.get(x)?.get(y) ?? null;
    }

    public forEachCell(
        callbackfn: (cell: WorldGridCell) => void,
        thisArg?: any
    ): void {
        this._cells.forEach((row) => {
            row.value.forEach((col) => {
                callbackfn(col.value);
            }, thisArg);
        }, thisArg);
    }
}

export { WorldGrid };
