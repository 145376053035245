import AtlasViewport from "./AtlasViewport";
import AtlasDimensionalElement from "./AtlasDimensionalElement";

export default class AtlasMapElement extends AtlasDimensionalElement {
    private _mapImage: HTMLImageElement | null = null;

    constructor(args?: { x?: number; y?: number; mapSrc: string }) {
        super({
            x: args?.x ?? 0,
            y: args?.y ?? 0,
            width: 20,
            height: 20,
        });

        if (args?.mapSrc != null) {
            this.loadMapImage(args.mapSrc);
        }
    }

    private loadMapImage(src: string) {
        const fetchMapImage = (): Promise<HTMLImageElement> => {
            return new Promise(() => {
                const img = new Image();

                img.onload = () => {
                    this._mapImage = img;
                    this._width = img.width;
                    this._height = img.height;
                    console.log(`Loaded map ${src}`);
                };
                img.onerror = (error) => {
                    console.error(error);
                };
                img.src = src;
            });
        };

        fetchMapImage();
    }

    public setMapSrc(src: string) {
        this._mapImage = null;

        this._width = 20;
        this._height = 20;

        this.loadMapImage(src);
    }

    public override draw(
        ctx: CanvasRenderingContext2D,
        viewport: AtlasViewport
    ) {
        if (this._mapImage == null) {
            ctx.fillStyle = "green";
            ctx.fillRect(
                this.x - viewport.x,
                this.y - viewport.y,
                this.width,
                this.height
            );
        } else {
            ctx.drawImage(
                this._mapImage,
                0,
                0,
                this._mapImage.width,
                this._mapImage.height,
                (this.x - viewport.x) * viewport.magnificationX,
                (this.y - viewport.y) * viewport.magnificationY,
                this.width * viewport.magnificationX,
                this.height * viewport.magnificationY
            );
        }
    }
}
