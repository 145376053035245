import ActiveUserContext from "../../utils/session/ActiveUserContext";
import AtlasCanvas from "./components/AtlasCanvas";
import AtlasViewport from "./objects/AtlasViewport";
import AtlasPinElement from "./objects/AtlasPinElement";
import FlexRow from "../../common/components/layout/Flexbox/FlexRow";
import PageFramework from "../../common/components/layout/PageFramework/PageFramework";
import { useContext, useEffect } from "react";
import AtlasUniverse from "./objects/AtlasUniverse";
import AtlasMapElement from "./objects/AtlasMapElement";
import Cookies from "universal-cookie";
import { useGetAtlasMarkers } from "../../net/AtlasManagement";

const atlasMap = new AtlasMapElement({
    mapSrc: "https://ayaseye.com/vc/res/EigolynWorld.jpg",
});
const atlasUniverse = new AtlasUniverse({ elements: [atlasMap] });
const atlasViewport = new AtlasViewport({
    x: -400,
    y: -300,
    width: 800,
    height: 600,
});

export default function AtlasPage(): JSX.Element {
    const activeUser = useContext(ActiveUserContext);
    const cookies = new Cookies();

    const [loadAtlasMarkers, isLoadingAtlasMarkers] = useGetAtlasMarkers();

    useEffect(() => {
        loadAtlasMarkers({
            variables: {
                atlas_id: 2,
                token: cookies.get("session_token") ?? undefined,
            },
            onComplete: (result) => {
                result.forEach((marker) => {
                    const pin = new AtlasPinElement({
                        pinX: () => {
                            return (
                                atlasMap.x + atlasMap.width * (marker.x ?? 0)
                            );
                        },
                        pinY: () => {
                            return (
                                atlasMap.y + atlasMap.height * (marker.y ?? 0)
                            );
                        },
                        mark: marker.mark,
                        icon: marker.icon,
                        title: marker.title,
                    });

                    atlasUniverse.addElement(pin);
                });
            },
            onError: (error) => {
                console.error(error);
            },
        });
    }, []);

    return (
        <PageFramework>
            <FlexRow>
                <AtlasCanvas
                    atlasUniverse={atlasUniverse}
                    atlasViewport={atlasViewport}
                    style={{ width: 800, height: 600 }}
                />
            </FlexRow>
        </PageFramework>
    );
}
