import * as React from "react";
import StatBoard from "../../../../utils/game/mechanics/StatBoard";
import FlexColumn from "../../../../common/components/layout/Flexbox/FlexColumn";
import FlexRow from "../../../../common/components/layout/Flexbox/FlexRow";
import { StatKeyMap } from "../../../../utils/game/mechanics/StatKeyMap";
import { useCallback, useMemo, useState } from "react";
import Tooltip from "../../../../common/components/wrappers/Tooltip/Tooltip";

import { ReactComponent as ProficiencyFrame } from "../../../../assets/images/character_sheet/svg/proficiency_frame.svg";

import "./ProficiencyPanel_style.css";

type Props = Readonly<{
    statBoard: StatBoard;
    statKey: string;
    modifierStatKey: string;
    isEditable?: boolean;
    onValueSave?: (val: number) => void;
    onPreviewValueChange?: (val: number) => void;
}>;

export default function ProficiencyPanel({
    statBoard,
    statKey,
    modifierStatKey,
    isEditable = false,
    onValueSave,
    onPreviewValueChange,
}: Props): JSX.Element {
    const score = Number.parseInt(statBoard.getValueByStatKey(statKey));
    const pointSpendScore = (() => {
        const values = statBoard.getFeaturesByStatKeyAndSource(
            statKey,
            "PointSpendValue"
        );
        if (values.length === 1 && values[0].type === "addend") {
            if (typeof values[0].value === "number") {
                return values[0].value;
            } else {
                return values[0].value();
            }
        }
        return 0;
    })();
    const scoreTips = [
        StatKeyMap.get(statKey) ?? statKey,
        ...statBoard.getTipsByStatKey(statKey, null, null, true),
    ];
    const modifier = Number.parseInt(
        statBoard.getValueByStatKey(modifierStatKey)
    );
    const modifierTips = [
        `${StatKeyMap.get(statKey) ?? statKey} Modifier`,
        ...statBoard.getTipsByStatKey(modifierStatKey, null, null, true),
    ];
    const modifierRollCount = useMemo(() => {
        return 1 + Math.max(0, Math.floor((score + 5) / 10));
    }, [score]);

    const [isEditing, setIsEditing] = useState<boolean>(false);
    const [editingValue, setEditingValue] = useState<number>(pointSpendScore);
    const [tooltipMode, setTooltipMode] = useState<"proficiency" | "modifier">(
        "proficiency"
    );

    const commitValue = useCallback((value: number) => {
        onValueSave?.(value);
    }, []);

    return (
        <Tooltip
            tooltipContent={
                tooltipMode === "proficiency" ? scoreTips : modifierTips
            }
            enabled={
                tooltipMode === "proficiency"
                    ? scoreTips.length > 0
                    : modifierTips.length > 0
            }
            direction="below"
        >
            <FlexColumn className="proficiency-panel-container">
                <ProficiencyFrame className="proficiency-panel-frame" />

                <div className="proficiency-panel-value-container">
                    <div
                        className="title"
                        onClick={() => {
                            if (isEditable) {
                                setIsEditing(true);
                            }
                        }}
                    >
                        {StatKeyMap.get(statKey) ?? statKey}
                    </div>
                    <div className="score-container">
                        {isEditing && (
                            <FlexRow className="score-editor" width={"full"}>
                                <div
                                    className="mod-button"
                                    tabIndex={1}
                                    onClick={() => {
                                        setEditingValue(() => {
                                            const newVal = editingValue - 1;
                                            onPreviewValueChange?.(newVal);
                                            return newVal;
                                        });
                                    }}
                                >
                                    -
                                </div>
                                <div
                                    className="value"
                                    tabIndex={2}
                                    onClick={() => {
                                        commitValue(editingValue);
                                        setIsEditing(false);
                                    }}
                                >
                                    {editingValue}
                                </div>

                                <div
                                    className="mod-button"
                                    tabIndex={3}
                                    onClick={() => {
                                        setEditingValue(() => {
                                            const newVal = editingValue + 1;
                                            onPreviewValueChange?.(newVal);
                                            return newVal;
                                        });
                                    }}
                                >
                                    +
                                </div>
                            </FlexRow>
                        )}
                        {!isEditing && (
                            <div
                                className={`score-field${
                                    isEditable ? ` editable` : ``
                                }`}
                                onClick={() => {
                                    setIsEditing(true);
                                }}
                            >
                                {score}
                            </div>
                        )}
                    </div>
                    <div
                        className="modifier-container"
                        onMouseOver={() => {
                            setTooltipMode("modifier");
                        }}
                        onMouseOut={() => {
                            setTooltipMode("proficiency");
                        }}
                    >
                        <div className="modifier">
                            {`${modifierRollCount}d6${
                                modifier === 0
                                    ? ``
                                    : ` ${
                                          modifier > 0
                                              ? `+${modifier}`
                                              : `${modifier}`
                                      }`
                            }`}
                        </div>
                    </div>
                </div>
            </FlexColumn>
        </Tooltip>
    );
}
