import { CharacterData } from "../../../../../utils/game/character/CharacterData";
import { DynamicInventoryDisplayMode } from "../../InventoryItemCollection/InventoryItemDynamicCollection";
import { KeyValuePair } from "../../../../../utils/sys/KeyValuePair";

import CharacterEquipmentPanel from "../../CharacterEquipmentPanel/CharacterEquipmentPanel";
import CharacterSheetWalletPanel from "../../CharacterSheetWalletPanel/CharacterSheetWalletPanel";
import FlexColumn from "../../../../../common/components/layout/Flexbox/FlexColumn";
import InventoryItemDynamicCollection from "../../InventoryItemCollection/InventoryItemDynamicCollection";
import InventoryItem from "../../../../../utils/game/mechanics/InventoryItem";

import { useCallback, useState } from "react";
import { UIInventoryItemContainerClickParams } from "../../InventoryItemSlot/InventoryItemContainer";
import { InventoryItemCollectionChangeResponse } from "../../InventoryItemCollection/InventoryItemCollection";

const stringifyInventory = (items: Array<InventoryItem>) => {
    return JSON.stringify(items.map((item) => item.toJSON()));
};

type Props = {
    characterData: CharacterData;
    isEditable?: boolean;
    inventoryDisplayMode?: DynamicInventoryDisplayMode;
    onInventoryDisplayModeChanged?: (mode: DynamicInventoryDisplayMode) => void;
    selectedItem?: InventoryItem | null;
    onItemClick?: (params?: UIInventoryItemContainerClickParams) => void;
    onCharacterDataValueChanged?: (val: KeyValuePair[]) => void;
    equipmentExpanded?: boolean;
    onEquipmentExpandedChanged?: (val: boolean) => void;
    inventoryExpanded?: boolean;
    onInventoryExpandedChanged?: (val: boolean) => void;
    walletExpanded?: boolean;
    onWalletExpandedChanged?: (val: boolean) => void;
};

export default function CharacterSheetInventoryTab(props: Props): JSX.Element {
    const [inventoryDisplayMode, setInventoryDisplayMode] =
        useState<DynamicInventoryDisplayMode>(
            props.inventoryDisplayMode ?? "list"
        );

    const addItemCallback = useCallback(
        (
            item: InventoryItem,
            index?: number
        ): InventoryItemCollectionChangeResponse => {
            const updatedItems = [...props.characterData.inventory];
            if (index === undefined) {
                updatedItems.push(item);
            } else {
                if (index >= updatedItems.length - 1) {
                    updatedItems.push(item);
                } else if (index < 0) {
                    updatedItems.splice(0, 0, item);
                } else {
                    updatedItems.splice(index + 1, 0, item);
                }
            }
            return {
                collectionID: "inventory",
                type: "collection",
                items: updatedItems,
                slotKey: "inventory",
            };
        },
        [props.characterData.inventory]
    );

    const onItemAdded = useCallback(
        (item: InventoryItem) => {
            const result = addItemCallback(item);
            props.onCharacterDataValueChanged?.([
                {
                    key: "inventory",
                    value: stringifyInventory(result.items),
                },
            ]);
        },
        [
            props.characterData.inventory,
            props.onCharacterDataValueChanged,
            addItemCallback,
        ]
    );

    const removeItemCallback = useCallback(
        (item: InventoryItem): InventoryItemCollectionChangeResponse => {
            const updatedItems = [...props.characterData.inventory];
            const index = updatedItems.findIndex((it) => it === item);

            if (index >= 0) {
                updatedItems.splice(index, 1);
            }

            return {
                collectionID: "inventory",
                type: "collection",
                items: updatedItems,
                slotKey: "inventory",
            };
        },
        [props.characterData.inventory]
    );

    const onItemRemoved = useCallback(
        (item: InventoryItem) => {
            props.onCharacterDataValueChanged?.([
                {
                    key: "inventory",
                    value: stringifyInventory(removeItemCallback(item).items),
                },
            ]);
        },
        [
            props.characterData.inventory,
            props.onCharacterDataValueChanged,
            removeItemCallback,
        ]
    );

    const removeItemAtCallback = useCallback(
        (index: number): InventoryItemCollectionChangeResponse => {
            const updatedItems = [...props.characterData.inventory];
            if (index >= 0 && index < updatedItems.length) {
                updatedItems.splice(index, 1);
            }

            return {
                collectionID: "inventory",
                type: "collection",
                items: updatedItems,
                slotKey: "inventory",
            };
        },
        [props.characterData.inventory]
    );

    const onItemRemovedAt = useCallback(
        (index: number) => {
            props.onCharacterDataValueChanged?.([
                {
                    key: "inventory",
                    value: stringifyInventory(
                        removeItemAtCallback(index).items
                    ),
                },
            ]);
        },
        [
            props.characterData.inventory,
            props.onCharacterDataValueChanged,
            removeItemAtCallback,
        ]
    );

    const setItemAtCallback = useCallback(
        (
            index: number,
            item: InventoryItem
        ): InventoryItemCollectionChangeResponse => {
            const updatedItems = [...props.characterData.inventory];
            if (index >= 0 && index < updatedItems.length) {
                updatedItems.splice(index, 1, item);
            }

            return {
                collectionID: "inventory",
                type: "collection",
                items: updatedItems,
                slotKey: "inventory",
            };
        },
        [props.characterData.inventory]
    );

    const onItemChanged = useCallback(
        (index: number, item: InventoryItem) => {
            props.onCharacterDataValueChanged?.([
                {
                    key: "inventory",
                    value: stringifyInventory(
                        setItemAtCallback(index, item).items
                    ),
                },
            ]);
        },
        [
            props.characterData.inventory,
            props.onCharacterDataValueChanged,
            setItemAtCallback,
        ]
    );

    return (
        <FlexColumn gap={8}>
            <CharacterEquipmentPanel
                characterData={props.characterData}
                isEditable={props.isEditable}
                onItemClick={props.onItemClick}
            />
            <InventoryItemDynamicCollection
                id="inventory"
                label="Inventory"
                items={props.characterData.inventory}
                isEditable={props.isEditable}
                displayMode={inventoryDisplayMode}
                onDisplayModeChanged={(mode) => {
                    setInventoryDisplayMode(() => {
                        props.onInventoryDisplayModeChanged?.(mode);
                        return mode;
                    });
                }}
                selectedItem={props.selectedItem}
                onItemClick={props.onItemClick}
                onItemAdded={onItemAdded}
                onItemRemoved={onItemRemoved}
                onItemRemovedAt={onItemRemovedAt}
                onItemChanged={onItemChanged}
                addItemCallback={addItemCallback}
                removeItemCallback={removeItemCallback}
                removeItemAtCallback={removeItemAtCallback}
                setItemAtCallback={setItemAtCallback}
                expanded={props.inventoryExpanded}
                onExpandedChanged={props.onInventoryExpandedChanged}
            />
            <CharacterSheetWalletPanel
                isEditable={props.isEditable}
                coinCounts={props.characterData.wallet}
                onCoinCountChanged={(newCoinCounts) => {
                    props.onCharacterDataValueChanged?.([
                        {
                            key: "wallet",
                            value: newCoinCounts.map((c) => {
                                return `[${c.coin.id}]${c.count}[/${c.coin.id}]`;
                            }),
                        },
                    ]);
                }}
                expanded={props.walletExpanded}
                onExpandedChanged={props.onWalletExpandedChanged}
            />
        </FlexColumn>
    );
}
