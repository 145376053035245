import { useAtlasCanvasInputHandler } from "../controls/AtlasCanvasInputHandler";
import { createRef, useCallback, useEffect, useRef, useState } from "react";
import { MutableRefObject } from "react";
import { Point2d } from "../../../common/types/Geometry";
import AtlasUniverse from "../objects/AtlasUniverse";
import AtlasViewport from "../objects/AtlasViewport";
import useCanvas from "../../../common/hooks/RenderingHooks";

type Props = {
    atlasUniverse: AtlasUniverse;
    atlasViewport: AtlasViewport;
    className?: string;
    style?: React.CSSProperties;
};

export default function AtlasCanvas({
    atlasUniverse,
    atlasViewport,
    className,
    style,
}: Props): JSX.Element {
    const lastMousePos: Point2d = { x: 0, y: 0 };

    const canvasRef = useCanvas((context: CanvasRenderingContext2D) => {
        const cW = context.canvas.width;
        const cH = context.canvas.height;

        context.fillStyle = "blue";
        context.fillRect(0, 0, cW, cH);

        /* console.log(
            atlasUniverse.elements.filter((e) => atlasViewport.overlaps(e))
                .length
        ); */
        atlasUniverse.elements.forEach((element) => {
            //if (atlasViewport.overlaps(element)) {
            element.draw(context, atlasViewport);
            //}
        });

        atlasViewport.draw(context, atlasViewport);
    });

    const onCanvasMouseMove = (e: MouseEvent) => {
        /* console.log(
            { x: e.offsetX, y: e.offsetY },
            atlasViewport.universePointToViewportPoint(
                atlasViewport.viewportPointToUniversePoint({
                    x: e.offsetX,
                    y: e.offsetY,
                })
            )
        ); */
    };

    const onCanvasMouseDrag = (e: MouseEvent) => {
        atlasViewport.translate(
            -e.movementX / atlasViewport.magnificationX,
            -e.movementY / atlasViewport.magnificationY
        );
    };

    const onCanvasMouseDown = (e: MouseEvent) => {
        lastMousePos.x = e.offsetX;
        lastMousePos.y = e.offsetY;
    };

    const onCanvasMouseUp = (e: MouseEvent) => {};

    const onCanvasMouseWheel = (e: WheelEvent) => {
        const scroll = e.deltaY;

        if (scroll > 0) {
            //atlasViewport.magnify(1 - 0.1, { x: e.offsetX, y: e.offsetY });
            atlasViewport.magnify(1 - 0.1, {
                x: atlasViewport.nativeWidth / 2,
                y: atlasViewport.nativeHeight / 2,
            });
        } else {
            //atlasViewport.magnify(1 + 0.1, { x: e.offsetX, y: e.offsetY });
            atlasViewport.magnify(1 + 0.1, {
                x: atlasViewport.nativeWidth / 2,
                y: atlasViewport.nativeHeight / 2,
            });
        }
    };

    useAtlasCanvasInputHandler(canvasRef, {
        onMouseMove: onCanvasMouseMove,
        onMouseDrag: onCanvasMouseDrag,
        onMouseDown: onCanvasMouseDown,
        onMouseUp: onCanvasMouseUp,
        onMouseWheel: onCanvasMouseWheel,
    });

    return (
        <canvas
            width={style?.width ?? undefined}
            height={style?.height ?? undefined}
            ref={canvasRef}
            className={`atlas-canvas${className == null ? "" : className}`}
            style={style}
        />
    );
}
