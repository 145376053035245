import { Dictionary } from "../../../../utils/sys/Dictionary";
import { Point2d } from "../../../../common/types/Geometry";
import {
    WorldGridCellState,
    WorldGridCellStateNullable,
} from "./WorldGridCellState";

class WorldGridCell {
    protected _x: number;
    protected _y: number;

    protected _n: WorldGridCell | null = null;
    protected _ne: WorldGridCell | null = null;
    protected _se: WorldGridCell | null = null;
    protected _s: WorldGridCell | null = null;
    protected _sw: WorldGridCell | null = null;
    protected _nw: WorldGridCell | null = null;

    protected _currentState: WorldGridCellState;
    protected _pendingState: WorldGridCellStateNullable | null;

    constructor(args: {
        x?: number;
        y?: number;
        n?: WorldGridCell | null;
        ne?: WorldGridCell | null;
        se?: WorldGridCell | null;
        s?: WorldGridCell | null;
        sw?: WorldGridCell | null;
        nw?: WorldGridCell | null;
    }) {
        this._x = args.x ?? -1;
        this._y = args.y ?? -1;

        this._n = args.n ?? null;
        this._ne = args.ne ?? null;
        this._se = args.se ?? null;
        this._s = args.s ?? null;
        this._sw = args.sw ?? null;
        this._nw = args.nw ?? null;

        this._currentState = { properties: new Dictionary() };
        this._pendingState = null;
    }

    get position(): Point2d {
        return { x: this._x, y: this._y };
    }
    set position(val: Point2d) {
        this._x = val.x;
        this._y = val.y;
    }

    // Neighbor Cell getters and setters
    get northCell(): WorldGridCell | null {
        return this._n;
    }
    set northCell(val: WorldGridCell | null) {
        this._n = val;
    }

    get northeastCell(): WorldGridCell | null {
        return this._ne;
    }
    set northeastCell(val: WorldGridCell | null) {
        this._ne = val;
    }

    get southeastCell(): WorldGridCell | null {
        return this._se;
    }
    set southeastCell(val: WorldGridCell | null) {
        this._se = val;
    }

    get southCell(): WorldGridCell | null {
        return this._s;
    }
    set southCell(val: WorldGridCell | null) {
        this._s = val;
    }

    get southwestCell(): WorldGridCell | null {
        return this._sw;
    }
    set southwestCell(val: WorldGridCell | null) {
        this._sw = val;
    }

    get northwestCell(): WorldGridCell | null {
        return this._nw;
    }
    set northwestCell(val: WorldGridCell | null) {
        this._nw = val;
    }

    get neighborCells(): Array<WorldGridCell> {
        return [
            this._n,
            this._ne,
            this._se,
            this._s,
            this._sw,
            this._nw,
        ].filter((c) => c != null) as Array<WorldGridCell>;
    }

    private createNewPendingState() {
        this._pendingState = { properties: new Dictionary() };
    }

    public hasProperty(key: string) {
        return this._currentState.properties.hasKey(key);
    }

    public getProperty(key: string) {
        return this._currentState.properties.get(key);
    }

    public setProperty(key: string, value: string | number | boolean | null) {
        if (value === null) {
            if (this._currentState.properties.hasKey(key)) {
                this._currentState.properties.remove(key);
            }
        } else {
            this._currentState.properties.set(key, value);
        }
    }

    public getPendingProperty(key: string) {
        return this._pendingState?.properties.get(key) ?? null;
    }

    public pendProperty(key: string, value: string | number | boolean | null) {
        if (this._pendingState == null) {
            this.createNewPendingState();
        }

        if (this._pendingState != null) {
            this._pendingState.properties.set(key, value);
        }
    }

    public advanceState() {
        if (this._pendingState != null) {
            this._pendingState.properties.forEach((kv) => {
                this.setProperty(kv.key, kv.value);
            });
            this._pendingState = null;
        }
    }
}

export { WorldGridCell };
