import ActiveUserContext from "../../utils/session/ActiveUserContext";
import Cookies from "universal-cookie";
import FlexRow from "../../common/components/layout/Flexbox/FlexRow";
import Icon from "../../common/components/Icon/Icon";
import PageFramework from "../../common/components/layout/PageFramework/PageFramework";
import Tooltip from "../../common/components/wrappers/Tooltip/Tooltip";
import WikiSearchBar from "./components/WikiSearchBar";

import { Whitelist, WhitelistUtils } from "../../utils/sys/Whitelist";
import { useContext, useEffect, useMemo, useState } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { useGetArticle } from "../../net/ArticleManagement";

import pencilIcon from "../../assets/images/common/svg/pencil.svg";

import "./Wiki_style.css";

type ArticleParams = Readonly<{
    id: number | null;
    article: string | null;
    search: string | null;
    category: string | null;
    system: string | null;
}>;

type ArticleData = Readonly<{
    id: number;
    title: string;
    text: string;
    categories: Array<string>;
    creator: { id: number; name: string } | null;
    editor: { id: number; name: string } | null;
    properties: { [key: string]: string | number | boolean }[];
    isWhitelistProtected: boolean;
    whitelist: Whitelist;
}>;

const blankArticle: ArticleData = {
    id: -1,
    title: "",
    text: "",
    categories: [],
    creator: null,
    editor: null,
    properties: [],
    isWhitelistProtected: false,
    whitelist: { viewers: [], editors: [] },
};

export default function WikiPage(): JSX.Element {
    const activeUser = useContext(ActiveUserContext);
    const [searchParams, setSearchParams] = useSearchParams();
    const [loadArticle, isLoadingArticle] = useGetArticle();
    const navigate = useNavigate();
    const cookies = new Cookies();

    const articleParams: ArticleParams = useMemo(() => {
        if (
            searchParams.get("id") === null &&
            searchParams.get("name") === null &&
            searchParams.get("article") === null &&
            searchParams.get("search") === null &&
            searchParams.get("category") === null &&
            searchParams.get("system") === null
        ) {
            return {
                id: null,
                article: "Homepage",
                search: null,
                category: null,
                system: null,
            };
        }

        return {
            id: (() => {
                const param = searchParams.get("id");
                const val = param === null ? NaN : parseInt(param);
                return val === null ? null : isNaN(val) ? null : val;
            })(),
            article: (() => {
                const param =
                    searchParams.get("name") ?? searchParams.get("article");
                return param;
            })(),
            search: (() => {
                const param = searchParams.get("search");
                return param;
            })(),
            category: (() => {
                const param = searchParams.get("category");
                return param;
            })(),
            system: (() => {
                const param = searchParams.get("system");
                return param;
            })(),
        };
    }, [searchParams]);

    const [articleData, setArticleData] = useState<ArticleData>(blankArticle);

    const canCreateArticle = useMemo(() => {
        return activeUser.rank >= 3;
    }, [activeUser.id, activeUser.rank]);

    const canEditArticle = useMemo(() => {
        return (
            articleData.id >= 1 &&
            activeUser.rank >= 3 &&
            (WhitelistUtils.canEdit(activeUser.id, articleData.whitelist) ||
                (articleData.creator?.id != null &&
                    activeUser.id === articleData.creator.id))
        );
    }, [
        activeUser.id,
        articleData.id,
        articleData.creator,
        articleData.isWhitelistProtected,
        articleData.whitelist,
    ]);

    useEffect(() => {
        loadArticle({
            variables: {
                identifier: {
                    id: articleParams.id ?? undefined,
                    article: articleParams.article ?? undefined,
                    search: articleParams.search ?? undefined,
                    category: articleParams.category ?? undefined,
                    system: articleParams.system ?? undefined,
                },
                token: cookies.get("session_token") ?? undefined,
                format: 1,
            },
            onComplete: (result) => {
                if (result != null) {
                    setArticleData({
                        id: result.id ?? -1,
                        title: result.title ?? "",
                        text: result.description ?? "",
                        categories: result.categories ?? [],
                        creator: result.creator
                            ? {
                                  id: result.creator.id ?? -1,
                                  name: result.creator.display_name ?? "",
                              }
                            : null,
                        editor: result.editor
                            ? {
                                  id: result.editor.id ?? -1,
                                  name: result.editor.display_name ?? "",
                              }
                            : null,
                        properties: result.properties ?? [],
                        isWhitelistProtected:
                            result.whitelist_protected ?? true,
                        whitelist: {
                            viewers: result.whitelist?.readers ?? [],
                            editors: result.whitelist?.writers ?? [],
                        },
                    });
                }
            },
            onError: (err) => {
                console.error("Failed to load article: ", articleParams);
                setArticleData(blankArticle);
            },
        });
    }, [articleParams]);

    return (
        <PageFramework>
            <FlexRow>
                {canCreateArticle && (
                    <Tooltip tooltipContent="Create Article">
                        <button
                            onClick={() => {
                                navigate(`/wiki/edit?action=create`);
                            }}
                        >
                            +
                        </button>
                    </Tooltip>
                )}
                <FlexRow alignment="end" gap={8}>
                    {canEditArticle && (
                        <Tooltip tooltipContent="Edit Article">
                            <button
                                onClick={() => {
                                    navigate(`/wiki/edit?id=${articleData.id}`);
                                }}
                            >
                                <Icon src={pencilIcon} size={16} />
                            </button>
                        </Tooltip>
                    )}
                    <FlexRow width="quarter">
                        <WikiSearchBar
                            onSearchSubmitted={(searchString) => {
                                window.location.href = `?search=${encodeURIComponent(
                                    searchString
                                )}`;
                            }}
                        />
                    </FlexRow>
                </FlexRow>
            </FlexRow>
            {isLoadingArticle() ? (
                <h1 style={{ textAlign: "center" }}>Loading...</h1>
            ) : (
                <>
                    <h1 style={{ textAlign: "center" }}>{articleData.title}</h1>
                    <hr />
                    <div className="article-container">
                        <div
                            className="article-content"
                            dangerouslySetInnerHTML={{
                                __html: articleData.text,
                            }}
                        ></div>
                        {articleData.categories.length > 0 && (
                            <div
                                className="article-category-bar"
                                dangerouslySetInnerHTML={{
                                    __html: `Categories: ${articleData.categories
                                        .map(
                                            (category) =>
                                                `<a href="?category=${category}">${category}</a>`
                                        )
                                        .join(" | ")}`,
                                }}
                            ></div>
                        )}
                        <div className="article-footer">
                            <div className="footer-left">
                                {articleData.creator == null
                                    ? null
                                    : `Creator: ${articleData.creator.name}`}
                            </div>
                            <div className="footer-right">
                                {articleData.editor == null
                                    ? null
                                    : `Last edit by: ${articleData.editor.name}`}
                            </div>
                        </div>
                    </div>
                </>
            )}
        </PageFramework>
    );
}
